<template>
	<div>
		<mdl-table :data="list" :columns="columns" :head-styles="headStyle" :styles="style" :height="height"></mdl-table>
		<mdl-paging :data="pageData" @change="onChangePage" />
	</div>
</template>
<script>
import blogApi from "@/apis/blog";

export default {
	props: {
		bgMap: {
			type: Object,
			default: {}
		},
		height: {
			type: Number,
			default: 0
		}
	},
	data () {
		return {
			list: [],
			height: 0,
			callback: null,
			headStyle: {
				align: "center",
				padding: "14px",
				background: "#E0E4FE"
			},

			style: {
				padding: "10px"
			},
			pageData: {
				page: 1,
				size: 15,
				total: 0
			},
			columns: [
				{
					text: '博客分类',
					name: "name",
					style: {
					width: 100,
					align: "center"
					},
					format: (row) => {
						return this.bgMap[row.cid].cname;
					}
				},
				{
					text: '博客标题',
					name: "title",
					format: (row) => {
						return `<a>${row.title}</a>`;
					},
					event: [
						{
							name: "click",
							method: function (that, data) {
								that.$router.push({ // 这里是修改地址栏
									path: `/blog/detail/${data.pid}`
								});
							}
						}
					]
				},
				{
					text: '点击 / 回复',
					name: "reply",
					style: {
					width: 100,
					align: "center",
					},
					format: (row) => {
						return `${row.click} / ${row.reply}`;
					}
				},
				{
					style: {
						width: 180,
						align: "center",
					},
					text: '发布人 / 发布时间',
					name: "publish",
					format: (row) => {
						return `<a href="/#/user/${row.uid}">${row.uname}</a><br />${row.publish}`;
					}
				}
			]
		}
	},
	methods: {
		async getDatas() {
			try {
				const blogList = await blogApi.list(`0:${this.pageData.page - 1}`);

				this.list = blogList.value.list;
				this.pageData.total = blogList.value.total;
			} finally {
			}
		},
		onChangePage (page) {
			this.pageData.page = page;
			this.getDatas();
		},
	}
}
</script>
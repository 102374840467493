var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "mdl-loading",
        { ref: "loadingBox", on: { load: _vm.getDatas } },
        [
          [
            _c("div", { staticClass: "blog-head-box" }, [
              _c("div", { staticClass: "blog-title-box" }, [
                _vm._v(_vm._s(_vm.blog.title)),
              ]),
              _c("div", { staticClass: "blog-info-box" }, [
                _c("div", [
                  _c("ul", [
                    _c("li", [_vm._v("博客分类")]),
                    _c("li", [_vm._v(_vm._s(_vm.blog.cname))]),
                  ]),
                  _c("ul", [
                    _c("li", [_vm._v("作者")]),
                    _c("li", [_vm._v(_vm._s(_vm.blog.uname))]),
                  ]),
                  _c("ul", [
                    _c("li", [_vm._v("发布时间")]),
                    _c("li", [_vm._v(_vm._s(_vm.blog.publish))]),
                  ]),
                ]),
                _c("div", [
                  _c("ul", [
                    _c("li", [_vm._v("关键字")]),
                    _c("li", [_vm._v(_vm._s(_vm.blog.keywords))]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "blog-btn-box" },
                [
                  _c(
                    "mdl-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "button", round: "" },
                      on: { click: _vm.onGoback },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "blog-detail-box",
                style: `height: ${_vm.height + 30}px;`,
              },
              [_c("div", { domProps: { innerHTML: _vm._s(_vm.blog.html) } })]
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
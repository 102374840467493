<template>
	<div>
		<mdl-loading ref="loadingBox" @load="getDatas">
			<template>
				<div class="blog-head-box">
					<div class="blog-title-box">{{ blog.title }}</div>
					<div class="blog-info-box">
						<div>
							<ul>
								<li>博客分类</li>
								<li>{{ blog.cname }}</li>
							</ul>
							<ul>
								<li>作者</li>
								<li>{{ blog.uname }}</li>
							</ul>
							<ul>
								<li>发布时间</li>
								<li>{{ blog.publish }}</li>
							</ul>
						</div>
						<div>
							<ul>
								<li>关键字</li>
								<li>{{ blog.keywords }}</li>
							</ul>
						</div>
					</div>
					<div class="blog-btn-box">
						<mdl-button type="button" style="width:100%;" @click="onGoback" round>返回</mdl-button>
					</div>
				</div>
				<div class="blog-detail-box" :style="`height: ${height + 30}px;`"><div v-html="blog.html"></div></div>
			</template>
		</mdl-loading>
	</div>
</template>
<script>
import blogApi from "@/apis/blog";

import "flycodes";
import "flyhighlighter";

import "flycodes/demo/FlyShow.css";
import "flyhighlighter/test/FlyHighLighter.css";

FlyCodes.setHighLighter(FlyHighLighter.execute);

export default {
	props: {
		data: {
			type: Object,
			default: null
		},
		height: {
			type: Number,
			default: 0
		},
	},
	data () {
		return {
			html: "blog detail.",
			isLoading: false,
			blog: {},
			hot50: [],
			lastest50: []
		}
	},
	watch: {
		data: function () {
			console.log(this.data);
			if (this.data) {
				this.value = this.data;
			}
		}
	},
	created () {
		this.code = this.$route.params.code;
	},
	methods: {
		async getDatas (callback) {

			if (this.isLoading) return;
			this.isLoading = true;

			try {
				this.loaded = false;
				const { value } = await blogApi.detail(this.code);
				const blog = value;

				const html = FlyCodes.toHTML(blog.readtext, blog.lang);
				blog.html = html;
				blog.publish = $utils.formatTime(blog.publish);

				this.blog = blog;

				const user = Ls.get("USER_INFO");
			} catch (e) {
				if (callback) {
					callback.err();
				}
			} finally {
				this.isLoading = false;
				if (callback) {
					callback.end();
				}
			}
		},
		onGoback () {
			this.$router.go(-1);
		}
	}
}
</script>
<style lang="scss" scoped>
body {
	height: 100%;
	overflow: hidden;
}
.body {
	overflow: hidden;
}

.blog-head-box {

	position: relative;

	.blog-title-box {
		font-size: 20px;
		font-weight: bold;
		padding: 5px 10px;
		margin-bottom: 10px;
	}

	.blog-info-box {
		>div {
			width: calc(100% - 15px);
			background: var(--c_white);

			ul, li {
				display: inline-block;
				margin: 0px;
				padding: 0px;
			}
			li {
				padding:5px;
			}
			li:first-child {
				width: 80px;
				background: var(--c_gray);
			}
		}
		>div:first-child {
			ul {
				width: 33.3%;
			}
		}
	}

	.blog-btn-box {
		position: absolute;
		right: 10px;
		top: 0px;
	}
}

.blog-detail-box {
	padding: 10px;
	>div {
		background: var(--c_light-white);
		height: 100%;
		overflow: auto;
	}

}


</style>
<template>
	<div>
		<mdl-loading ref="loadingBox" @load="init">
			<template>
			<div class="blog-box">
				<mdl-tree :data="bgList" />
				<div>
					<div v-if="isList">
						<list :height="height" :bg-map="bgMap" ref="list" @detail="onDetail" />
					</div>
					<div v-if="isDetail || isEdit">
						<detail-edit :height="height" ref="detailEdit" :data="data" />
					</div>
				</div>
				<div>
					<div class="blog-tag-title-box">最热 50 个 标签</div>
					<div class="blog-tag-value-box">
						<mdl-tag v-for="(hot, index) in hot50" :key="index" :style="`font-size:${20 * hot.cnt / 10}px;`">{{ hot.tname }}</mdl-tag>
					</div>
					<div class="blog-tag-title-box">最新 50 个 标签</div>
					<div class="blog-tag-value-box">
						<mdl-tag v-for="(lastest, index) in lastest50" :key="index">{{ lastest.tname }}</mdl-tag>
					</div>
				</div>
			</div>
			</template>
		</mdl-loading>
	</div>
</template>
<script>
import blogApi from "@/apis/blog";

import DetailEdit from "./detail-edit";
import list from "./list";

export default {
	components: {
		DetailEdit,
		list
	},
	data () {
		return {
			bgMap: {},
			bgList: [],
			isList: true,
			isDetail: false,
			isEdit: false,
			data: null,
			loading: null,
			height: 0,
			hot50: [],
			lastest50: []
		}
	},
	watch: {
		$route: function (v1, v2) {
			if (v1.name === "blog" && this.loading !== null) {
				this.onList();
			}
			if (v1.name === "blog-detail") {
				this.onDetail();
			}
		}
	},
	created () {
		this.height = document.body.scrollHeight - 180;
		this.isList = this.$route.name === "blog";
		this.isDetail = this.$route.name === "blog-detail";
		this.isEdit = this.$route.name === "blog-edit";
	},
	methods: {
		init (loading) {
			this.loading = loading;
			this.getDatas(loading);
		},
		async getDatas (loading) {
			this.loading = loading;
			try {
				loading.start();

				const [ blogclass, tags ] = await Promise.all([
					blogApi.blogclass(),
					blogApi.tags()
				]);

				const bgList = blogclass.value;
				const output = [], bgMap = {};

				for (let bgClz of bgList) {
					bgMap[bgClz.cid] = bgClz;
					if (bgClz.parent === 0) {
						bgClz.children = [];
						output.push(bgClz);
					}
				}

				for (let bgClz of bgList) {
					if (bgClz.parent !== 0) {
						const parent = bgMap[bgClz.parent];
						parent.children.push(bgClz);
					}
				}

				this.bgList = output;
				this.bgMap = bgMap;

				this.hot50 = tags.value.hot50;
				this.lastest50 = tags.value.lastest50;

				console.log(this.hot50, this.lastest50);

				if (this.isList) {
					await this.$refs.list.getDatas();
				}
				if (this.isDetail || this.isEdit) {
					await this.$refs.detailEdit.getDatas();
				}
				
			} catch (err) {
				console.log(err);
				loading.err();
			} finally {
				loading.end();
			}
		},
		onList () {
			this.isList = true;
			this.isDetail = false;

			this.getDatas(this.loading);
		},
		onDetail () {
			this.isList = false;
			this.isDetail = true;

			this.$nextTick(async () => {
				await this.$refs.detailEdit.getDatas(this.loading);
			});
		}
	}
}
</script>
<style lang="scss" scoped>
.blog-box {
	margin-top: 10px;
	height: 100%;
	>div {
		display: inline-block;
		vertical-align: top;
		height: 100%;
	}
	>div:first-child {
		width: 300px;
		background: #FF0000;
	}
	>div:nth-child(2) {
		width: calc(100% - 700px);
	}
	>div:last-child {
		width: 400px;
	}

	.blog-tag-title-box {
		padding: 14px;
		background: var(--c_dark-blue);
		color: var(--c_white);
		margin-bottom: 5px;
		font-weight: bold;
	}
	.blog-tag-value-box {
		>div {
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;
		}
	}
}
</style>
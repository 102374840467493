export const blogclass = () => {
	return $http.get(`/blog/blogclass`);
};

export const detail = (pid) => {
	return $http.get(`/blog/detail/${pid}`);
};

export const list = (params) => {
	return $http.get(`/blog/list/${params}`);
};

export const tags = (params) => {
	return $http.get(`/blog/tags`);
};

export default {
	blogclass,
	list,
	detail,
	tags
};
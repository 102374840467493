var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mdl-table", {
        attrs: {
          data: _vm.list,
          columns: _vm.columns,
          "head-styles": _vm.headStyle,
          styles: _vm.style,
          height: _vm.height,
        },
      }),
      _c("mdl-paging", {
        attrs: { data: _vm.pageData },
        on: { change: _vm.onChangePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
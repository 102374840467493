var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "mdl-loading",
        { ref: "loadingBox", on: { load: _vm.init } },
        [
          [
            _c(
              "div",
              { staticClass: "blog-box" },
              [
                _c("mdl-tree", { attrs: { data: _vm.bgList } }),
                _c("div", [
                  _vm.isList
                    ? _c(
                        "div",
                        [
                          _c("list", {
                            ref: "list",
                            attrs: { height: _vm.height, "bg-map": _vm.bgMap },
                            on: { detail: _vm.onDetail },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDetail || _vm.isEdit
                    ? _c(
                        "div",
                        [
                          _c("detail-edit", {
                            ref: "detailEdit",
                            attrs: { height: _vm.height, data: _vm.data },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", [
                  _c("div", { staticClass: "blog-tag-title-box" }, [
                    _vm._v("最热 50 个 标签"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "blog-tag-value-box" },
                    _vm._l(_vm.hot50, function (hot, index) {
                      return _c(
                        "mdl-tag",
                        {
                          key: index,
                          style: `font-size:${(20 * hot.cnt) / 10}px;`,
                        },
                        [_vm._v(_vm._s(hot.tname))]
                      )
                    }),
                    1
                  ),
                  _c("div", { staticClass: "blog-tag-title-box" }, [
                    _vm._v("最新 50 个 标签"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "blog-tag-value-box" },
                    _vm._l(_vm.lastest50, function (lastest, index) {
                      return _c("mdl-tag", { key: index }, [
                        _vm._v(_vm._s(lastest.tname)),
                      ])
                    }),
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }